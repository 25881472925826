import { Alert, Box, Snackbar } from "@mui/material";
import HomeIcons from "../components/HomeIcons";
import Taskbar from "../components/Taskbar";
import Welcome from "../components/Welcome";
import Draggable from "react-draggable";
import { useState } from "react";
import Video from "../components/Video";
import Video2 from "../components/Video2";
import Countdown from "../components/Countdown";
import HowToBuy from "../components/HowToBuy";
import Chart from "../components/Chart";
import ContactInfo from "../components/ContactInfo";
import MediaPlayer from "../components/MediaPlayer";
import coinGif from '../assets/images/coin.gif';
import Uniswap from "../components/Uniswap";
import Loremap from "../components/Loremap";

const Home = () => {
    const [welcomeOpen, setWelcomeOpen] = useState(true);
    const [videoOpen, setVideoOpen] = useState(false);
    const [video2Open, setVideo2Open] = useState(false);
    const [mediaPlayerOpen, setMediaPlayerOpen] = useState(false);
    const [howToBuyOpen, setHowToBuyOpen] = useState(false);
    const [countdownOpen, setCountdownOpen] = useState(false);
    const [chartOpen, setChartOpen] = useState(false);
    const [contactOpen, setContactopen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [swapOpen, setSwapOpen] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);

    const handleSnackbarOpen = () => setSnackbarOpen(true);
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    return(
        <>
        <Taskbar setContactopen={setContactopen} contactOpen={contactOpen} setWelcomeOpen={setWelcomeOpen} setLoreOpen={setVideoOpen} setHowToBuyOpen={setHowToBuyOpen}/>
        {/* -- Spinning Pepe Here -- */}
        <div sx={{ height: '100vh', position: 'relative', bgColor: 'red', width: '100%', overflow: 'hidden'}}>
        
        <video id="background-video" playsInline autoPlay loop muted style={{ width: '100vw', height: '100vh', objectFit: 'cover', position: 'fixed', top: 0, left: 0}}>
            <source src="/lore-background.mp4" type="video/mp4"/>
        </video>
        
        <Box className="drag-container" sx={{overflow: 'hidden'}}>
        <Box
            component={'img'}
            src={coinGif}
            sx={{
                position: 'absolute',
                zIndex: {md: 3, xs: -10 },
                height: '200px',
                bottom: '75px',
                right: '20px',
                transition: '0.15s ease'
            }}
        />
        <Box sx={{ zIndex: {md: 2, xs: -10 }, height: '220px', width: '200px', position: 'absolute', right: '-25px', bottom: '15px', transition: '0.15s ease' }}>
            <Box sx={{backgroundColor: 'white', width: '57%', height: '60%', borderRadius: '50%'}} />
        </Box>
            <HomeIcons setMapOpen={setMapOpen} setSwapOpen={setSwapOpen} setMediaPlayerOpen={setMediaPlayerOpen} setWelcomeOpen={setWelcomeOpen} setVideoOpen={setVideoOpen} setCountdownOpen={setCountdownOpen} setHowToBuyOpen={setHowToBuyOpen} setChartOpen={setChartOpen} setVideo2Open={setVideo2Open}/>
            { welcomeOpen ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: {md: '10%', xs: '5%'},
                            left: {md: '20%', xs: '5%'},
                            width: {md: '60%', xs: '90%'},
                        }}
                    >
                        <Welcome setOpen={setWelcomeOpen}/>
                    </Box>
                </Draggable>
            ) : ("") }
            { mapOpen ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: {md: '10%', xs: '5%'},
                            left: {md: '20%', xs: '5%'},
                            width: {md: '60%', xs: '90%'},
                        }}
                    >
                        <Loremap setOpen={setMapOpen}/>
                    </Box>
                </Draggable>
            ) : ("") }
            { videoOpen ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: '10%',
                            left: {md: '20%', xs: '5%'},
                            width: {md: '60%', xs: '90%'},
                        }}
                        >
                    <Video setOpen={setVideoOpen}/>
                </Box></Draggable>
            ) : ("")}
            { video2Open ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: '10%',
                            left: {md: '20%', xs: '5%'},
                            width: {md: '60%', xs: '90%'},
                        }}
                        >
                    <Video2 setOpen={setVideo2Open}/>
                </Box></Draggable>
            ) : ("")}
            { countdownOpen ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: {md: '10%', xs: '1%' },
                            left: {md: '20%', xs: '5%'},
                        }}
                    >
                        <Countdown setOpen={setCountdownOpen}/>
                    </Box>
                </Draggable>
            ) : ("")}
            { howToBuyOpen ? (
                <Draggable>
                    <Box 
                        sx={{
                            zIndex: 5,
                            position: 'absolute',
                            top: {md: '10%', xs: '1%' },
                            left: {md: '20%', xs: '5%'},
                            width: {md: '60%', xs: '90%'},
                        }}
                    >
                    <HowToBuy setOpen={setHowToBuyOpen} handleSnackbarOpen={handleSnackbarOpen}/>
                    </Box>
                </Draggable>
            ) : ("")} 
            
            { chartOpen ? (
                <Draggable><Box 
                sx={{
                    zIndex: 5,
                    position: 'absolute',
                    top: {md: '10%', xs: '1%' },
                    left: {md: '20%', xs: '5%'},
                    width: {md: '60%', xs: '90%'},
                }}
            >
                    <Chart setOpen={setChartOpen}/>    
                </Box></Draggable>
            ) : ("") }
            { contactOpen ? (
                <Draggable>
                <Box 
                    sx={{
                        zIndex: 5,
                        position: 'absolute',
                        top: '10%',
                        left: {md: '20%', xs: '5%'},
                        width: {md: '60%', xs: '90%'},
                        height: "auto",

                    }}
                >
                    <ContactInfo setOpen={setContactopen}/>
                </Box>
            </Draggable>
            ) : <></>}
            { mediaPlayerOpen ? (
                <Draggable>
                <Box 
                    sx={{
                        zIndex: 5,
                        position: 'absolute',
                        top: '10%',
                        left: {md: '20%', xs: '5%'},
                        width: {md: '60%', xs: '90%'},
                    }}
                >
                    <MediaPlayer setOpen={setMediaPlayerOpen}/>
                </Box>
            </Draggable>
            ) : ""}
            { swapOpen ? (
                <Draggable>
                <Box 
                    sx={{
                        zIndex: 5,
                        position: 'absolute',
                        top: '10%',
                        left: {md: '20%', xs: '5%'},
                        width: {md: '60%', xs: '90%'},
                    }}
                >
                    <Uniswap setOpen={setSwapOpen}/>
                </Box>
            </Draggable>
            ) : ""}
        </Box>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                <p style={{fontFamily: 'ms_sans_serif'}}>Copied to clipboard</p>
            </Alert>
        </Snackbar>
        </div>
        </>
    )
};

export default Home;