import { Stack } from '@mui/material';
import React from 'react';
import { Button, ScrollView, Window, WindowContent, WindowHeader } from 'react95';

const ContactInfo = ({setOpen}) => {

  let audio = new Audio("/audio/click.mp3");

  const links = {
    email: 'mailto:info@pepe-lore.xyz',
    telegram: 'https://t.me/loreportal',
  }
  const handleCopyAddress = () => {
    audio.play()
    navigator.clipboard.writeText(process.env.REACT_APP_CONTRACT_ADDRESS)
    alert("Copied to clipboard!")
  };

  return(
    <Window className='fullSize' >
      <WindowHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <p>Contact Information</p>
          <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}} >X</Button>
        </Stack>
      </WindowHeader>
      <WindowContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ScrollView className='scrollView'>
          <div className='column'>
            <Stack spacing={2} direction='row' sx={{alignItems: 'center', justifyContent: 'center',marginBottom: '20px', marginTop: '20px'}}>
              <p style={{fontSize: '2rem'}}>Contact Info</p>
            </Stack>
            <p style={{fontSize: '1.2rem'}}>Contract address: </p>
            <p className='wrapped-anchor' onClick={() => handleCopyAddress()} onTouchEnd={() => handleCopyAddress()} style={{fontSize: '1.2rem', marginBottom: '20px', '&:hover': { cursor: 'pointer' }}}>
              {process.env.REACT_APP_CONTRACT_ADDRESS}
            </p>
            <p style={{fontSize: '1.2rem', marginBottom: '20px'}}>Email: <a href={links.email} target='_blank' rel='noreferrer noopener'>info@pepe-lore.xyz</a></p>
            <p style={{fontSize: '1.2rem', marginBottom: '20px'}}>Telegram: <a href='https://t.me/loreportal' rel='noreferrer noopener' target='_blank'>t.me/loreportal</a></p>
            <p style={{fontSize: '1.2rem', marginBottom: '20px'}}>Twitter: <a href='https://twitter.com/lore_erc20' rel='noreferrer noopener' target='_blank'>@lore_erc20</a></p>
            
          </div>
        </ScrollView>
      </WindowContent>
    </Window>
  );
};

export default ContactInfo;
