import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, ScrollView, Window, WindowContent, WindowHeader } from 'react95';

const Loremap = ({setOpen}) => {

  let audio = new Audio("/audio/click.mp3");
  const targetDate = new Date('2023-09-15T12:00:00+00:00');
    const calcTimeDifference = () => {
        const now = new Date();
        return Math.floor((targetDate - now) / 1000);
    };

    const [secondsLeft, setSecondsleft] = useState(calcTimeDifference());
    
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsleft(calcTimeDifference());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

  return(
    <Window className='fullSize' >
      <WindowHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <p>Loremap</p>
          <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}} >X</Button>
        </Stack>
      </WindowHeader>
      <WindowContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ScrollView className='scrollView'>
          <Box className='column' sx={{ py: {md: '250px', xs: '35vh'}}}>
            <h1 style={{fontSize: '1.8rem'}}>{secondsLeft}</h1>
          </Box>
        </ScrollView>
      </WindowContent>
    </Window>
  );
};

export default Loremap;
