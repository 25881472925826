import { useEffect, useState } from "react";
import { Button, Counter, Window, WindowHeader } from "react95";

const Countdown = ({setOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    const targetDate = new Date(2023, 9, 18, 23, 59, 59);
    const calcTimeDifference = () => {
        const now = new Date();
        return Math.floor((targetDate - now) / 1000);
    };

    const [secondsLeft, setSecondsleft] = useState(calcTimeDifference());
    
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsleft(calcTimeDifference());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return(
        <Window className="fullSize">
            <WindowHeader>
            <div className="box" style={{justifyContent: 'space-between'}}>
                <p>Countdown </p>
                <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}} >X</Button>
            </div>
            </WindowHeader>
            <div style={{ padding: "7px" }}>
            <Counter
                value={secondsLeft}
            />
            </div>
        </Window>
    );
};

export default Countdown;