import { Stack } from "@mui/material";
import { Button, Window, WindowContent, WindowHeader } from "react95";

const Chart = ({setOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    return(
        <Window className="fullSize">
            <WindowHeader>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <p>Chart</p>
                    <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}}>X</Button>
                </Stack>
            </WindowHeader>
            <WindowContent>
            <div id="dexscreener-embed">
                <iframe title="dexscreener" src={`https://dexscreener.com/ethereum/${process.env.REACT_APP_PAIR}?embed=1&theme=dark&trades=0&info=0`}>
                </iframe>
            </div>
            </WindowContent>
        </Window>

    );
};

export default Chart;