import { Grid } from "@mui/material";
import { Button } from "react95";

import computerIMG from '../assets/images/computer.png';
import discIMG from '../assets/images/disc.png';
import mediaPlayerIMG from '../assets/images/media_player.png';
import hourglassIMG from '../assets/images/hourglass.png';
import chartIMG from '../assets/images/chart.png';
import musicIMC from '../assets/images/music.png';
import uniswapIMG from '../assets/images/uniswap.png';
import Map from '../assets/images/map.png';

const HomeIcons = ({setWelcomeOpen, setVideoOpen, setHowToBuyOpen, setCountdownOpen, setChartOpen, setVideo2Open, setMediaPlayerOpen, setSwapOpen, setMapOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    return(
        <>
            <Grid 
                container
                direction={"column"}
                sx={{ height: '95%'}}
            >
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setWelcomeOpen(true)}} onTouchEnd={() => {audio.play();setWelcomeOpen(true)}}>
                    <img alt="lore.txt" src={computerIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Welcome</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setVideoOpen(true)}} onTouchEnd={() => {audio.play();setVideoOpen(true)}}>
                    <img alt="lore.txt" src={discIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Lore.mp4</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setSwapOpen(true)}} onTouchEnd={() => {audio.play();setSwapOpen(true)}}>
                    <img alt="lore.txt" src={uniswapIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Uniswap.html</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setVideo2Open(true)}} onTouchEnd={() => {audio.play();setVideo2Open(true)}}>
                    <img alt="lore.txt" src={discIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Lore 2.mp4</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setHowToBuyOpen(true)}} onTouchEnd={() => {audio.play();setHowToBuyOpen(true)}}>
                    <img alt="lore.txt" src={mediaPlayerIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>How to buy.pdf</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setMediaPlayerOpen(true)}} onTouchEnd={() => {audio.play();setMediaPlayerOpen(true)}}>
                    <img alt="lore.txt" src={musicIMC} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Lore.mp3</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setCountdownOpen(true)}} onTouchEnd={() => {audio.play();setCountdownOpen(true)}}>
                    <img alt="lore.txt" src={hourglassIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Countdown.exe</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setChartOpen(true)}} onTouchEnd={() => {audio.play();setChartOpen(true)}}>
                    <img alt="lore.txt" src={chartIMG} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Chart.png</p>
                </Button>
                <Button variant="flat" style={{ padding: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '120px', width: '120px', marginBottom: '25px', backgroundColor: 'rgba(0, 0, 0, 0)', border: '0px'}}
                onClick={() => {audio.play();setMapOpen(true)}} onTouchEnd={() => {audio.play();setMapOpen(true)}}>
                    <img alt="lore.txt" src={Map} style={{height: '45px'}}/>
                    <p style={{color: 'white'}}>Loremap</p>
                </Button>
            </Grid>
        </>
    )
}

export default HomeIcons;