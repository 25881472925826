import { Box, Stack } from '@mui/material';
import React from 'react';
import { Button, ScrollView, Window, WindowContent, WindowHeader } from 'react95';
import loreCoin from '../assets/images/coin.gif'
import telegramIMG from '../assets/images/telegram.png'
import uniswapPNG from '../assets/images/uniswap.png'
import chartPNG from '../assets/images/chart.png';
import Xlogo from '../assets/images/x.png'

const Welcome = ({setOpen}) => {

  let audio = new Audio("/audio/click.mp3");

  const handleCLickLink = (url) => {
    audio.play();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCopyAddress = () => {
    audio.play()
    navigator.clipboard.writeText(process.env.REACT_APP_CONTRACT_ADDRESS)
    alert("Copied to clipboard!")
  };

  const links = {
    dextools: `https://www.dextools.io/app/en/ether/pair-explorer/${process.env.REACT_APP_PAIR}`,
    dexview: `https://www.dexview.com/eth/${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    dexscreener: `https://dexscreener.com/ethereum/${process.env.REACT_APP_PAIR}`,
    telegram: 'https://t.me/loreportal',
    uniswap: `https://app.uniswap.org/#/swap?outputCurrency=${process.env.REACT_APP_CONTRACT_ADDRESS}&inputCurrency=ETH`,
    twitter: 'https://twitter.com/lore_erc20'
  }

  return(
    <Window className='fullSize' >
      <WindowHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <p>Welcome to $LORE</p>
          <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}} >X</Button>
        </Stack>
      </WindowHeader>
      <WindowContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ScrollView className='scrollView'>
          <div className='column'>
            <p style={{fontSize: '2rem'}}>Welcome to $LORE</p>
            <Box alt='lore' draggable={false} component={'img'} src={loreCoin} sx={{ width: {md: "200px", xs: '150px'} }}/>
            <p className='wrapped-p'>Every meme, has its origin story.</p>
            <div style={{ cursor: 'pointer' }}>
            <p className='wrapped-anchor' onClick={() => handleCopyAddress()} onTouchEnd={() => handleCopyAddress()} style={{fontSize: '1rem', marginTop: '8px', '&:hover': { cursor: 'pointer' }}}>
              {process.env.REACT_APP_CONTRACT_ADDRESS}

            </p>
            </div>
            <Window style={{ marginTop: '15px', marginBottom: '15px', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px'}}>
              <p>$LORE Links</p>
              <Stack spacing={{md: 0, xs: 2}} direction={{xs: "column", md: "row"}} sx={{ mt: 1, mb: 1, justifyContent: 'center', width: {md: '500px', xs: '200px'}}}>
                <Button className="socials" style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => handleCLickLink(links.telegram)} onTouchEnd={() => handleCLickLink(links.telegram)}
                >
                  <img alt='telegram' src={telegramIMG} style={{ height: '50%', m: 'auto', paddingRight: '5px' }}/>
                  Telegram
                </Button>
                <Button className="socials" style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => handleCLickLink(links.uniswap)} onTouchEnd={() => handleCLickLink(links.uniswap)}
                >
                  <img alt='uniswap' src={uniswapPNG} style={{ height: '50%', m: 'auto', paddingRight: '5px' }}/>
                  Uniswap
                </Button>
              </Stack>
              <Stack spacing={{md: 0, xs: 2}} direction={{xs: "column", md: "row"}} sx={{ mt: 1, mb: 1, justifyContent: 'center', width: {md: '500px', xs: '200px'}}}>
                <Button className="socials" style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => handleCLickLink(links.dextools)} onTouchEnd={() => handleCLickLink(links.dexview)}
                >
                  <img alt='uniswap' src={chartPNG} style={{ height: '50%', m: 'auto', paddingRight: '5px' }}/>
                  Chart
                </Button>
                <Button className="socials" style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => handleCLickLink(links.twitter)} onTouchEnd={() => handleCLickLink(links.twitter)}
                >
                  <img alt='uniswap' src={Xlogo} style={{ height: '50%', m: 'auto', paddingRight: '5px' }}/>
                  Twitter
                </Button>
              </Stack>
            </Window>
          </div>
        </ScrollView>
      </WindowContent>
    </Window>
  );
};

export default Welcome;