import { useState } from "react";
import { AppBar, Button, MenuList, MenuListItem, Toolbar } from "react95";
import logoIMG from '../assets/images/pepe.png';

const Taskbar = ({setContactopen, setWelcomeOpen, setLoreOpen, setHowToBuyOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    const [open, setOpen] = useState(false);

    return(
        <AppBar style={{ position: 'absolute', bottom: 0, top: "auto", zIndex: 100 }}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <Button
                        onClick={() => {audio.play();setOpen(!open)}}
                        onTouchEnd={() => {audio.play();setOpen(!open)}}
                        active={open}
                        style={{ fontWeight: 'bold' }}
                    >
                        <img src={logoIMG} alt='lore logo' style={{ height: '20px', marginRight: 4}}/>
                        Start
                    </Button>
                    
                    { open && (
                        <MenuList
                            style={{
                                position: 'absolute',
                                left: '0',
                                bottom: '100%'
                            }}
                            onClick={() => setOpen(false)}
                            onTouchEnd={() => setOpen(false)}
                        >
                            <MenuListItem className="hover" onClick={() => {audio.play();setWelcomeOpen(true)}} onTouchEnd={() => {audio.play();setWelcomeOpen(true)}}>
                                <span  role='img' aria-label='👨‍💻'>
                                    Welcome
                                </span>
                            </MenuListItem>
                            <MenuListItem className="hover" onClick={() => {audio.play();setLoreOpen(true)}} onTouchEnd={() => {audio.play();setLoreOpen(true)}}>
                                <span role='img' aria-label='👨‍💻'>
                                    Lore
                                </span>
                            </MenuListItem>                            
                            <MenuListItem className="hover" onClick={() => {audio.play();setHowToBuyOpen(true)}} onTouchEnd={() => {audio.play();setHowToBuyOpen(true)}}>
                                <span role='img' aria-label='👨‍💻'>
                                    How to buy
                                </span>
                            </MenuListItem>
                        </MenuList>
                    )}
                </div>
                <Button
                        onClick={() => {audio.play();setContactopen(!open)}}
                        onTouchEnd={() => {audio.play();setContactopen(!open)}}
                        style={{ fontWeight: 'bold' }}
                    >
                        
                        Contact
                    </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Taskbar;