import { Stack } from "@mui/material";
import { Button, Window, WindowContent, WindowHeader } from "react95";

const Video2 = ({setOpen}) => {

    let audio = new Audio("/audio/click.mp3");
  
    const start = () => {
        audio.play();
    };

    return(
        <Window className="fullSize">
            <WindowHeader>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <p>Lore.mp4</p>
                    <Button onClick={() => {start();setOpen(false)}} onTouchEnd={() => {start();setOpen(false)}}>X</Button>
                </Stack>
            </WindowHeader>
            <WindowContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <video autoPlay width={"100%"} style={{overflow: 'hidden'}}>
                    <source src='/lore2.mp4'/>
                </video>
            </WindowContent>
        </Window>
    );
};

export default Video2;