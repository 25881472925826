import { Button, Window, WindowContent, WindowHeader } from "react95";
import Typewriter from "../components/Typewriter";
import pepeWizard from '../assets/images/LoreWizard.png'
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import coinGif from '../assets/images/coin.gif';

const Entry = ({setLoggedIn}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isPepe, setIsPepe] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 1400);
        return () => clearTimeout(timer);
    }, [])
    useEffect(() => {
        document.body.style.overflow = "hidden";
        const timer = setTimeout(() => {
            setIsPepe(true);
        }, 1700);
        return () => clearTimeout(timer);
    }, [])
    let audio = new Audio("/audio/click.mp3");
    return(
        <div
            style={{
                backgroundColor: 'teal',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden'
            }}
        >
        <Box
            component={"img"}
                sx={{
                    height: {md: "400px", xs: '250px'},
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    opacity: isPepe ? 1 : 0, 
                    transform: `translateY(${isPepe ? 0 : "400px"})`,
                    transition: "transform 1.5s",
                }}
            src={pepeWizard}
        />
            <Box 
                component={'img'}
                src={coinGif}
                sx={{
                    position: 'absolute',
                    zIndex: 1,
                    height: {md: '200px', xs: '120px'},
                    opacity: isPepe ? 1 : 0,
                    transition: "opacity 1s",
                    top: '15%'
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 0,
                    height: {md: '200px', xs: '120px'},
                    width: {md: '200px', xs: '120px'},
                    top: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: isPepe ? 1 : 0,
                    transition: "opacity 1s",
                }}
            >
                <div style={{height: '58%', width: '58%', backgroundColor: 'white', borderRadius: '50%'}}/>
            </Box>
            
            <p style={{ marginBottom: '35px', color: 'white', fontSize: '2rem', fontWeight: '600' }}>
                <Typewriter text="Welcome to $LORE..." delay={60}/>
            </p>
            <Box sx={{ 
                width: {
                    md: '500px',
                    sx: '90%'
                },
                height: '170px'
                }}>
            <Window style={{
                width: '100%',
                height: '100%',
                opacity: isVisible ? 1 : 0
            }}> 
                <WindowHeader>Welcome to $LORE</WindowHeader>
                <WindowContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{fontSize: '1.2rem'}}>Click to enter the $LORE website</p>
                        <Button
                        style={{width: '40%', marginTop: '20px'}}
                            onClick={() => {audio.play();setLoggedIn(true)}} onTouchEnd={() => {audio.play();setLoggedIn(true)}}
                        >Enter</Button>
                </WindowContent>
            </Window>
            </Box>
            
        </div>
    );
};

export default Entry;