import { Box, Stack } from "@mui/material";
import { Button, ScrollView, Window, WindowContent, WindowHeader } from "react95";
import uniswapIMG from '../assets/images/uniswap.png';

const HowToBuy = ({setOpen, handleSnackbarOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    const uniswapURL = `https://app.uniswap.org/#/swap?outputCurrency=${process.env.REACT_APP_CONTRACT_ADDRESS}&inputCurrency=ETH`;
    const handleClickLink = (url) => {
        audio.play();
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleCopyAddress = () => {
        handleSnackbarOpen();
        audio.play()
        navigator.clipboard.writeText(process.env.REACT_APP_CONTRACT_ADDRESS)
    };

    return(
        <Window className="fullSize">
            <WindowHeader>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <p>How to buy.pdf</p>
                    <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}}>X</Button>
                </Stack>
            </WindowHeader>
            <WindowContent style= {{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ScrollView className="scrollView">
                    <div className='column' style={{marginLeft: '20px', marginRight: '20px'}}>
                        <p style={{ marginTop: '30px', fontSize: '2rem', fontWeight: '600'}}>How to buy $LORE</p>
                        <Box sx={{width: '75%', display: 'flex', pt: 2, justifyContent: 'center'}}>
                            <p style={{marginRight: '5px', fontWeight: 'bold'}}>1.</p>
                            <p>Copy the $LORE contract address using the button below:</p>
                        </Box>
                        <Box sx={{width: '75%', display: 'flex', pt: 2, justifyContent: 'center'}}>
                            <Button onClick={handleCopyAddress} onTouchEnd={handleCopyAddress}
                            >Copy contract address</Button>
                        </Box>
                        <Box sx={{width: '75%', display: 'flex', pt: 2, justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{marginRight: '5px', fontWeight: 'bold'}}>2.</p>
                            <p>Follow the link to Uniswap</p>
                            <Button className="socials" style={{ marginLeft: '10px', marginRight: '10px' }}
                                    onClick={() => handleClickLink(uniswapURL)}
                                    onTouchEnd={() => handleClickLink(uniswapURL)}>
                                    <img alt='uniswap' src={uniswapIMG} style={{ height: '50%', m: 'auto', paddingRight: '5px' }}/>
                                    <a href="google.com">Uniswap</a>
                            </Button>
                        </Box>
                        <Box sx={{width: '75%', display: 'flex', pt: 2, justifyContent: 'center'}}>
                            <p style={{marginRight: '5px', fontWeight: 'bold'}}>3.</p>
                            <p>Click 'Select token' and paste the contract address for $LORE</p>
                        </Box>
                        <Box sx={{width: '75%', display: 'flex', pt: 2, justifyContent: 'center'}}>
                            <p style={{marginRight: '5px', fontWeight: 'bold'}}>4.</p>
                            <p>Follow instructions to connect your wallet to Uniswap</p>
                        </Box>
                        <Box sx={{mb: 5, width: '75%', display: 'flex', pt: 2, justifyContent: 'center'}}>
                            <p style={{marginRight: '5px', fontWeight: 'bold'}}>5.</p>
                            <p>Enter an amount to buy and click 'Swap'</p>
                        </Box>
                    </div>
                </ScrollView>
            </WindowContent>
        </Window>
    );
};

export default HowToBuy;