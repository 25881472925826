import { Box, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Button, Monitor, Window, WindowContent, WindowHeader } from "react95";
import pause from '../assets/images/pause.png';
import play from '../assets/images/play.png';

const MediaPlayer = ({setOpen}) => {
    let audio = new Audio("/audio/click.mp3");
    const [paused, setPaused] = useState(null);
    const [volume, setVolume] = useState(0.6)
    const videoRef = useRef(null);

    useEffect(() => {
        changeVolume()
    })
    
    const increaseVolume = () => {
        if(volume < 0.91) {
            setVolume(volume + .1);
        }
        else {
            setVolume(1)
        }
        changeVolume();
    }

    const decreaseVolume = () => {
        if(volume > 0.09) {
            setVolume(volume - 0.1)
        }
        else {
            setVolume(0);
        }
        changeVolume();
    }

    const togglePlayback = () => {
        audio.play()
        if (videoRef.current) {
            if (paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
            setPaused(!paused);
        }
    }

    const changeVolume = () => {
        if (videoRef.current) {
            videoRef.current.volume = volume;
        }
    }
    return(
        <Window className="fullSize">
            <WindowHeader>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <p>Lore.mp3</p>
                    <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}}>X</Button>
                </Stack>
            </WindowHeader>
            <WindowContent style= {{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{width: '100%', display: 'flex', flexDirection: {md: 'row', xs: 'column'} , justifyContent: 'center', alignItems: 'center'}}>
                    <Box 
                        sx={{
                            width: {md: '40%', xs: '100%'}, 
                            height: '100%', 
                            display: 'flex', 
                            justifyContent: 'center',
                            mb: {md: 0, xs: 5}
                        }}
                    >
                        <Monitor style={{ height: '200%' }}>
                            <div style={{height: '100%', width: '100%'}}>
                                <video ref={videoRef} id="video" autoPlay style={{height: '100%', width: '100%'}}>
                                    <source  src="/NewMediaPlayerVideo.mp4"/>
                                </video>
                            </div>
                        </Monitor>
                    </Box>
                    <Box className='column' sx={{width: {md: '60%', xs: '100%'}, height: '100%', display: 'flex', justifyContent: 'center', mb: {md: 0, xs: 5} }}>
                        <Button onClick={() => {togglePlayback()}} onTouchEnd={() => {togglePlayback()}} style={{marginBottom: '15px'}}> 
                                <img alt="play" src={paused ? play : pause} style={{height: '25px'}}/>
                        </Button>
                        <p>Volume</p>
                        <Stack spacing={2} direction='row' sx={{ mt: 2, width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                            <Button onClick={() => {audio.play();decreaseVolume()}} onTouchEnd={() => {audio.play();decreaseVolume()}} disabled={volume < .05} style={{fontSize: '2rem', paddingInline: '16px'}}>-</Button>
                            <p>{Math.round(volume * 100) }%</p>
                            <Button onClick={() => {audio.play();increaseVolume()}} onTouchEnd={() => {audio.play();increaseVolume()}} disabled={volume >=0.99 } style={{fontSize: '1.5rem', paddingInline: '15px'}}>+</Button>
                        </Stack>
                    </Box>
                </Box>
            </WindowContent>
        </Window>
    );
};

export default MediaPlayer;