import { Stack } from '@mui/material';
import React from 'react';
import { Button, ScrollView, Window, WindowContent, WindowHeader } from 'react95';

const Uniswap = ({setOpen}) => {

  let audio = new Audio("/audio/click.mp3");
  
  return(
    <Window className='fullSize' >
      <WindowHeader>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <p>Uniswap</p>
          <Button onClick={() => {audio.play();setOpen(false)}} onTouchEnd={() => {audio.play();setOpen(false)}} >X</Button>
        </Stack>
      </WindowHeader>
      <WindowContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ScrollView className='scrollView'>
        <iframe
            title='uniswap'
             src={`https://app.uniswap.org/#/swap?outputCurrency=${process.env.REACT_APP_CONTRACT_ADDRESS}&inputCurrency=ETH`}
             height="660px"
             width="100%"
             style={{ borderRadius: '1px'}}
        />
        </ScrollView>
      </WindowContent>
    </Window>
  );
};

export default Uniswap;
